.fuctionalcnt {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 90px;
}

.functionalcntlist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 70px;
  width: 100%;
}

.functionalcnttop {
  font-size: 36px;
  text-align: center;
  margin: 0 10px 40px 10px;
}

.functinalcntlistleft {
  display: grid;
  gap: 70px;
}

.functionallistitem {
  display: grid;
  gap: 15px;
  max-width: 300px;
}

.functionallistitem img {
  border-radius: 40px;
}

.functinalistitemtitle {
  font-size: 20px;
  font-weight: 700;
}

.functinalistitemtext {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .functionalcntlist {
    display: flex;
    flex-direction: column;
    gap: 70px;
  }

  .functinalcntlistleft {
    width: 250px;
    margin: 0 auto;
  }

  .functionallistitem img {
    max-width: 250px;
    border-radius: 20px;
  }
}
