.navbar_cnt {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: rgba(149, 157, 165, 0.09) 0px 8px 24px;
  padding: 0 15px;

}

.navbar_body {
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarleft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbarleft img {
  width: 35px;
}
.navbarlogo {
  cursor: pointer;
  font-weight: 800;
}
 
.navbarmiddle {
  display: flex;
  align-items: center;
  gap: 25px;
}

.navbarmiddlelink {
  text-decoration: none;
  color: #000;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.navbarright {
  display: flex;
  align-items: center;
  gap: 10px;
}

.authsign {
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 30px;
  border-radius: 999px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authlogine {
  width: 140px;
  object-fit: contain;
  cursor: pointer;
}

.authsign {
  color: #fff;
  background-color: #0da5a5;
  border: none;
}

.menuicon {
  display: none;
}

.navbarbottom {
  display: none;
}

.authlogineeas {
  width: 140px;
  object-fit: contain;
  cursor: pointer;
}

@media screen and (max-width: 1090px) {
  .navbar_cnt {
  }
}

@media screen and (max-width: 900px) {
  /* .doverlay {
    z-index: 15;
    position: fixed;
    top: 60px;
    bottom: 0;
    right: 0;
    left: 0;
  } */

  .authlogine {
    display: none;
  }

  .authlogineeas {
    width: 140px;
    object-fit: contain;
    cursor: pointer;
  }

  .navbar_cnt {
  }
  .menuicon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .navbarbottom {
    z-index: 20;
    display: grid;
    border-top: 1px solid #e5eaee;
    margin-top: 15px;
    padding-top: 15px;
    background-color: #fff;
  }

  .navbarbottomlinks {
    display: grid;
    gap: 10px;
    margin-bottom: 20px;
  }

  .navbarbottombtns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .authloginn,
  .authsignn {
    cursor: pointer;
    font-family: inherit;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 30px;
    border-radius: 999px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .authloginn {
    background-color: transparent;
    border: 1px solid #e5eaee;
  }

  .authsignn {
    color: #fff;
    background-color: #0da5a5;
    border: none;
  }
  .navbarmiddle {
    display: none;
  }

  .authlogin {
    display: none;
  }

  .authsign {
    display: none;
  }
}
