.addtespaycardcnt {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 90px;
  margin-bottom: 90px;
}

.addtespaycardtitle {
  font-size: 36px;
  text-align: center;
  margin: 0 10px 20px 10px;
}

.addtespaycardparagraph {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.addtespaycardsec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
}

.addtespaycardsecitems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f5f9;
  padding: 50px 50px 0px 50px;
  border-radius: 20px;
  gap: 20px;
}

.addtespaytitle {
  font-size: 20px;
  font-weight: 700;
}

.addtespaypara {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}

.addtespayanytime {
    margin: 50px auto;
    background-color: #f3f5f9;
    border-radius: 20px;
    display: flex;
    padding: 50px 50px 0px 50px;
    align-items: center;
    gap: 70px;
}

.tespayanytimetitle {
    font-size: 30px;
    font-weight: 700px;
    margin-bottom: 20px;
}

.tespayanytimeparagraph {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 50px;
}

@media screen and (max-width: 900px) {
   .addtespaycardsec {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;
    }
  
    .addtespayanytime {
        flex-direction: column;
       margin: 0 20px;
       margin-top: 70px;
    }
  }
  